import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Input, Typography, Tabs, Select, Modal } from 'antd';
import { LockTwoTone, MailTwoTone, UserOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';
import { LOGIN } from '../../redux/types';
import { supabase } from '../../utils/supabaseClient';
import { Footer } from '../../components/Footer';
import { FirstDecorator } from '../../components/Decorators';
import { useUser } from '../../utils/context';
import { Navbar } from '../../components/NavBar';
// import {
// 	Carousel,
// 	CarouselContent,
// 	CarouselItem,
// } from '@/components/ui/carousel';
// import Image from 'next/image';


const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
  const [signUpRole, setSignUpRole] = useState(null);
  const [activeTab, setActiveTab] = useState('login');
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const authUser = useSelector((state) => state.auth.authUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useUser();

  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  const showResetModal = () => {
    setIsResetModalVisible(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: values.email,
        password: values.password,
      });

      if (error) {
        NotificationManager.error(error.message);
        setLoading(false);
        return;
      }

      if (data) {
        const { user } = data.session;

        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .select('user_id, role')
          .eq('user_id', user.id)
          .maybeSingle();

        if (accountError) {
          NotificationManager.error(accountError.message);
          setLoading(false);
          return;
        }

        if (accountData) {
          setUser({ ...user, account_role: accountData.role, account_id: accountData.id });
        }

        dispatch({ type: LOGIN, payload: data.session.user });

        if (accountData.role === 'provider') {
          navigate('/app/myrequests');
        } else if (accountData.role === 'customer') {
          navigate('/app/customerrequests');
        }
      }
    } catch (err) {
      console.error('Login error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (values) => {
    if (!signUpRole) {
      NotificationManager.error('Please select a role');
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const { data, error } = await supabase.auth.signUp({
        email: values.email,
        password: values.password,
        options: {
          data: {
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            role: signUpRole,
          },
        },
      });

      if (error) {
        NotificationManager.error(error.message);
      } else {
        const { user } = data;
        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .insert([
            {
              user_id: user.id,
              firstname: values.first_name,
              lastname: values.last_name,
              phone: values.phone,
              email: values.email,
              role: signUpRole,
            },
          ]);

        if (accountError) {
          NotificationManager.error(accountError.message);
          return;
        }

        if (accountData) {
          const account_id = accountData[0].id;
          setUser({ ...user, account_role: signUpRole, account_id });
          dispatch({ type: LOGIN, payload: user });

          NotificationManager.success('Account created successfully! Redirecting...');
          setIsSignUpModalVisible(false);
          if (signUpRole === 'provider') {
            navigate('/app/myrequests');
          } else if (signUpRole === 'customer') {
            navigate('/app/customerrequests');
          }
        }
      }
    } catch (err) {
      console.error('Sign up error:', err);
      NotificationManager.error('Failed to sign up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (values) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(values.email, {
        redirectTo: 'https://app.intested.com/resetpassword',
      });
      if (error) {
        NotificationManager.error(error.message);
      } else {
        NotificationManager.success('Password reset email sent!');
        setIsResetModalVisible(false);
      }
    } catch (error) {
      NotificationManager.error('Failed to send password reset email.');
    }
  };

  if (authUser) {
    navigate('/');
    return null;
  }

  const getHeaderText = () => {
    if (type === 'provider') {
      return 'Signup/Login to be a reviewer';
    } else if (type === 'customer') {
      return 'Login to request a service';
    }
    return 'Login';
  };

  const getFormDescription = () => {
    if (type === 'provider') {
      return 'Create an account to become a reviewer and review services.';
    } else if (type === 'customer') {
      return 'Login to access our services and place your service requests.';
    }
    return 'Please login to continue.';
  };

  // Media queries: use window size to change styles dynamically
  const containerStyle = {
    width: '600px',
    height: '600px',
    marginLeft: '30px',
    top: '-40px',
    borderRadius: '50%',
    overflow: 'hidden',
  };

  if (window.innerWidth < 768) {
    containerStyle.width = '100%'; // Adjust width for mobile
    containerStyle.height = 'auto'; // Adjust height for mobile
    containerStyle.marginLeft = '0'; // Remove margin for mobile
    containerStyle.top = '0'; // Remove top margin for mobile
  }

  return (
    <>
   
      <Navbar />
      <div className="bg-[#fffcf3] flex flex-col min-h-screen w-full overflow-hidden min-h-screen">
        <div className="flex flex-col md:flex-row justify-center py-10 md:py-16 px-4 md:px-16 gap-10">
          <div className="w-full md:w-[60%] flex justify-center">
            <FirstDecorator />
          </div>

          <div
            className="w-full md:w-[60%] lg:w-[50%] flex justify-center relative bg-[#FFFFFF]"
            style={containerStyle}
          >
            <div
              style={{
                maxWidth: 400,
                width: '100%',
                marginTop: '100px',
              }}
            >
              <Title level={3} style={{ textAlign: 'center', marginBottom: 24 }}>
                Welcome to Inested Services
              </Title>
              <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <TabPane key="login" tab="Login">
                  <Form layout="vertical" onFinish={onFinish}>
                    <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
                      <Input prefix={<MailTwoTone />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password!' }]}>
                      <Input.Password prefix={<LockTwoTone />} placeholder="Password" />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" block loading={loading}>
                      Login
                    </Button>
                    <div style={{ marginTop: 16, textAlign: 'center' }}>
                      <Button onClick={showResetModal}>Forgot Password?</Button>
                    </div>
                  </Form>
                </TabPane>

                <TabPane key="signup" tab="Sign Up">
                  <Form layout="vertical" onFinish={handleSignUp}>
                    <Form.Item name="role" rules={[{ required: true, message: 'Please select your role!' }]}>
                      <Select value={signUpRole} onChange={setSignUpRole} placeholder="Select your role">
                        <Option value="customer">User</Option>
                        <Option value="provider">Reviewer</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
                      <Input prefix={<MailTwoTone />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password!' }]}>
                      <Input.Password prefix={<LockTwoTone />} placeholder="Password" />
                    </Form.Item>
                    <Form.Item name="first_name" rules={[{ required: true, message: 'Please enter your first name!' }]}>
                      <Input prefix={<UserOutlined />} placeholder="First Name" />
                    </Form.Item>
                    <Form.Item name="last_name" rules={[{ required: true, message: 'Please enter your last name!' }]}>
                      <Input prefix={<UserOutlined />} placeholder="Last Name" />
                    </Form.Item>
                    <Button onClick={() => setIsSignUpModalVisible(true)} type="primary" htmlType="submit" block loading={loading}>
                      Sign Up
                    </Button>
                  </Form>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>


        {/* <div className="w-full md:w-[60%] flex justify-end">
          <FirstDecorator />
        </div> */}
      </div>



      <Modal
        visible={isResetModalVisible}
        onCancel={() => setIsResetModalVisible(false)}
        onOk={() => setIsResetModalVisible(false)}
        footer={null}
        centered
      >
        <Form layout="vertical" onFinish={handleResetPassword}>
          <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
            <Input prefix={<MailTwoTone />} placeholder="Enter your email" />
          </Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Send Reset Link
          </Button>
        </Form>
      </Modal>

      <Footer />
    </>
  );
};

export default Login;
